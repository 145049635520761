<template>
	<div
		id="details"
		class="background-white w-100 mb-60 py-60 px-80 shadow-lg rounded"
	>
		<h1 class="mb-10">Avtala</h1>

		<p class="mb-30 color-grey">Her kanst tú stovna eina nýggja avtalu. Tað er bert krav at velja kunda. Hini feltini kunnu vera tóm</p>

		<h2 class="mb-10">Generelt</h2>

		<div class="mb-20">
			<label
				class="block mb-10"
				for="currency"
				>Kundi</label
			>

			<CustomerSearchInput
				v-if="form.customer === null"
				@change="customerSearchChange"
			/>

			<div
				class="selected-customer"
				v-if="form.customer"
				title="Vel ein annan kunda"
			>
				<div class="customer-name">{{ form.customer && form.customer.name }}</div>

				<div
					class="customer-remove"
					@click="form.customer = null"
				>
					<i class="fas fa-times"></i>
				</div>
			</div>
		</div>

		<div class="grid grid-2 grid-gap-20 mb-20">
			<div>
				<label
					class="block mb-10"
					for="currency"
					>Slag av avtalu</label
				>

				<select
					class="v-select"
					id="agreementType"
					v-model="form.agreementType"
				>
					<option
						v-for="(agreementType, index) in agreementTypes"
						:key="`agreement_type_${index}`"
						:value="agreementType"
					>
						{{ agreementType.name }}
					</option>
				</select>

				<div class="small-text color-red mt-10 px-5"></div>
			</div>

			<div>
				<label
					class="block mb-10"
					for="stepList"
					>Leistur at fylgja</label
				>

				<select
					class="v-select"
					id="stepList"
					v-model="form.stepList.id"
				>
					<option value="">Ongin leistur</option>

					<option
						v-for="(stepList, index) in stepLists"
						:key="`step_list_${index}`"
						:value="stepList.id"
					>
						{{ stepList.name }}
					</option>
				</select>
			</div>
		</div>

		<template v-if="!hasField('to')">
			<div class="mb-30">
				<v-date-picker
					v-model="form.from"
					mode="dateTime"
					is24hr
					:popover="{ visibility: 'focus' }"
				>
					<template #default="{ inputValue, inputEvents }">
						<div>
							<label
								class="block mb-10"
								for="from"
							>
								Avtala byrjar
							</label>

							<div class="date-input">
								<input
									type="text"
									:value="inputValue"
									v-on="inputEvents"
								/>

								<div
									class="date-input-icon"
									v-if="!form.to"
								>
									<i class="fa fa-calendar-alt"></i>
								</div>

								<div
									class="date-input-icon"
									v-if="form.to"
									@click="form.to = null"
								>
									<i class="fas fa-times"></i>
								</div>
							</div>
						</div>
					</template>
				</v-date-picker>
			</div>
		</template>

		<template v-if="hasField('to')">
			<div class="grid grid-2 grid-gap-20 mb-30">
				<v-date-picker
					v-model="form.from"
					mode="dateTime"
					is24hr
					:model-config="{ timeAdjust: '00:00' }"
					:popover="{ visibility: 'focus' }"
				>
					<template #default="{ inputValue, inputEvents }">
						<div>
							<label
								class="block mb-10"
								for="from"
								>Avtala byrjar</label
							>

							<div class="date-input">
								<input
									type="text"
									:value="inputValue"
									v-on="inputEvents"
								/>

								<div
									class="date-input-icon"
									v-if="!form.from"
								>
									<i class="fa fa-calendar-alt"></i>
								</div>

								<div
									class="date-input-icon"
									v-if="form.from"
									@click="form.from = null"
								>
									<i class="fas fa-times"></i>
								</div>
							</div>
						</div>
					</template>
				</v-date-picker>

				<v-date-picker
					v-model="form.to"
					mode="dateTime"
					is24hr
					:min-date="form.from ? form.from : null"
					:popover="{ visibility: 'focus' }"
				>
					<template #default="{ inputValue, inputEvents }">
						<div>
							<label
								class="block mb-10"
								for="from"
								>Avtala endar</label
							>

							<div class="date-input">
								<input
									type="text"
									:value="inputValue"
									v-on="inputEvents"
								/>

								<div
									class="date-input-icon"
									v-if="!form.to"
								>
									<i class="fa fa-calendar-alt"></i>
								</div>

								<div
									class="date-input-icon"
									v-if="form.to"
									@click="form.to = null"
								>
									<i class="fas fa-times"></i>
								</div>
							</div>
						</div>
					</template>
				</v-date-picker>
			</div>
		</template>

		<label
			v-show="hasField('isShortTermAgreement')"
			class="cursor-pointer"
		>
			<div class="mb-10">Vikuskiftisleiga</div>

			<div
				class="switch"
				:class="{ active: form.isShortTermAgreement }"
			>
				<input
					type="checkbox"
					v-model="form.isShortTermAgreement"
				/>

				<div></div>
			</div>
		</label>

		<hr class="my-20" />

		<h2 class="mb-10">Kostnaðir</h2>

		<div class="grid grid-2 grid-gap-30 mb-30">
			<div v-show="hasField('oneTimePrice')">
				<label class="block mb-10">Eingangsgjald</label>

				<input
					type="text"
					v-model="form.oneTimePrice"
				/>
			</div>

			<div v-show="hasField('monthlyPrice')">
				<label class="block mb-10">Mánaðargjald</label>
				<input
					type="text"
					v-model="form.monthlyPrice"
				/>
			</div>

			<div v-show="hasField('feePrice')">
				<label class="block mb-10">Avgreiðslugjald</label>

				<input
					type="text"
					v-model="form.feePrice"
				/>
			</div>
		</div>

		<hr class="my-20" />

		<h2 class="mb-10">Avtalu upplýsingar</h2>

		<div class="grid grid-2 grid-gap-30 mb-30">
			<div v-show="hasField('swedbank_e_com_number')">
				<label class="block mb-10">Swedbank E-Com nummar</label>
				<input
					type="text"
					v-model="form.swedbank_e_com_number"
				/>
			</div>

			<div v-show="hasField('swedbank_pos_number')">
				<label class="block mb-10">Swedbank POS nummar</label>
				<input
					type="text"
					v-model="form.swedbank_pos_number"
				/>
			</div>

			<div v-show="hasField('tof_number')">
				<label class="block mb-10">TOF</label>
				<input
					type="text"
					v-model="form.tof_number"
				/>
			</div>

			<div v-show="hasField('numberOfTerminals')">
				<label class="block mb-10">Tal av terminalum</label>

				<input
					type="number"
					v-model="form.numberOfTerminals"
					min="1"
				/>
			</div>
		</div>

		<div
			class="mb-30"
			v-show="hasField('terms')"
		>
			<label class="block mb-10">Treytir</label>

			<textarea
				v-model="form.terms"
				cols="30"
				rows="4"
				style="resize: none"
			></textarea>
		</div>

		<div v-show="hasField('terminalSupplier') || hasField('terminalType') || hasField('psp') || hasField('otherPsp')">
			<hr class="my-20" />

			<h2 class="mb-10">Terminal upplýsingar</h2>

			<div class="grid grid-2 grid-gap-30">
				<div v-show="hasField('terminalSupplier')">
					<label class="block mb-10">Terminal Leverandør</label>
					<input
						type="text"
						v-model="form.terminalSupplier"
					/>
				</div>

				<div v-show="hasField('terminalType')">
					<label class="block mb-10">Slag av terminali</label>
					<input
						type="text"
						v-model="form.terminalType"
					/>
				</div>

				<label
					v-show="hasField('psp')"
					class="align-center cursor-pointer"
				>
					<div class="mb-10">PSP Nets?</div>

					<div
						class="switch"
						:class="{ active: form.psp }"
					>
						<input
							type="checkbox"
							v-model="form.psp"
						/>

						<div></div>
					</div>
				</label>

				<div v-show="hasField('otherPsp') && !form.psp">
					<label class="block mb-10">Annað PSP</label>
					<input
						type="text"
						v-model="form.otherPsp"
					/>
				</div>
			</div>
		</div>

		<hr class="my-20" />

		<div class="grid mt-20">
			<h2 class="mb-20">Skjøl at útfylla</h2>

			<div class="grid grid-2 grid-gap-10">
				<label
					style="font-weight: normal"
					class="cursor-pointer"
					v-for="(originalFile, index) in originalFiles"
					:key="`original_file_${index}`"
				>
					<input
						type="checkbox"
						:value="originalFile"
						v-model="form.originalFiles"
					/>

					<span class="ml-10">{{ originalFile.name }}</span>
				</label>
			</div>
		</div>
	</div>
</template>

<script>
import { required, numeric } from 'vuelidate/lib/validators';

import CustomerSearchInput from '@/components/CustomerSearchInput.vue';

import axios from 'axios';

export default {
	name: 'AgreementDetails',

	components: {
		CustomerSearchInput,
	},

	props: ['value'],

	data() {
		return {
			originalFiles: [],

			agreementTypes: [],

			stepLists: [],

			form: {
				originalFiles: [],
				agreementType: {},
				stepList: {},
				customer: null,
				swedbank_e_com_number: null,
				swedbank_pos_number: null,
				oneTimePrice: '',
				numberOfTerminals: 1,
				feePrice: '',
				tof_number: null,
				monthlyPrice: '',
				price: '',
				terms: '',

				terminalSupplier: '',
				terminalType: '',
				psp: true,
				otherPsp: '',

				from: null,
				to: null,
			},
		};
	},

	mounted() {
		axios
			.get('/agreement-types')
			.then((response) => {
				this.agreementTypes = response.data;

				// Hide KYC agreements
				this.agreementTypes = this.agreementTypes.filter((type) => type.name != 'KYC');

				if (!this.agreementTypes.length) {
					this.form.agreementType = {
						name: '',
						id: 0,
					};

					return;
				}

				this.form.agreementType = this.agreementTypes[0];
			})
			.catch((error) => {
				console.log('Error fetching agreement types', error);
			});

		axios
			.get('/steps/lists')
			.then((response) => {
				this.stepLists = response.data;

				this.form.stepList = { id: '' };
			})
			.catch((error) => {
				console.log('Error fetching step lists', error);
			});

		axios
			.get(`/original-files`)
			.then((response) => {
				this.originalFiles = response.data;
			})
			.catch((error) => {
				console.error('Unable to fetch original files', error);
			});
	},

	validations: {
		form: {
			customer: {
				required,
			},

			numberOfTerminals: {
				numeric,
			},
		},
	},

	watch: {
		value: {
			deep: true,

			handler(newValue) {
				if (newValue.customer) {
					this.form.customer = newValue.customer;
				}
			},
		},

		form: {
			deep: true,

			immediate: true,

			handler() {
				this.$emit('input', {
					agreementType: this.form.agreementType,
					customer: this.form.customer,
					stepList: this.form.stepList,
					swedbank_e_com_number: this.form.swedbank_e_com_number,
					swedbank_pos_number: this.form.swedbank_pos_number,
					tof_number: this.form.tof_number,
					from: this.form.from ? new Date(this.form.from) : null,
					to: this.form.to ? new Date(this.form.to) : null,
					originalFiles: this.form.originalFiles,
					oneTimePrice: this.form.oneTimePrice,
					feePrice: this.form.feePrice,
					monthlyPrice: this.form.monthlyPrice,
					price: this.form.price,
					terms: this.form.terms,
					terminalSupplier: this.form.terminalSupplier,
					terminalType: this.form.terminalType,
					psp: this.form.psp,
					otherPsp: this.form.otherPsp,
					isShortTermAgreement: this.form.isShortTermAgreement,
					numberOfTerminals: this.form.numberOfTerminals,
				});

				return this.$emit('valid', !this.$v.form.$invalid);
			},
		},
	},

	methods: {
		hasField(field) {
			const map = {
				to: ['Stutttíðarleiga'],
				monthlyPrice: ['Langtíðarleiga', 'Vibrant', 'Tænastusáttmáli'],
				oneTimePrice: ['Langtíðarleiga', 'Vibrant', 'Stutttíðarleiga', 'Nethandil', 'Tænastusáttmáli'],
				feePrice: ['Stutttíðarleiga'],
				isShortTermAgreement: ['Stutttíðarleiga'],
				swedbank_e_com_number: ['Nethandil'],
				swedbank_pos_number: ['Langtíðarleiga', 'Vibrant', 'Tænastusáttmáli', 'Einans innloysing'],
				tof_number: ['Langtíðarleiga', 'Vibrant', 'Nethandil', 'Tænastusáttmáli', 'Einans innloysing'],
				terms: ['Stutttíðarleiga'],
				terminalSupplier: ['Langtíðarleiga', 'Vibrant', 'Tænastusáttmáli', 'Einans innloysing'],
				terminalType: ['Langtíðarleiga', 'Vibrant', 'Tænastusáttmáli', 'Einans innloysing'],
				psp: ['Langtíðarleiga', 'Vibrant', 'Tænastusáttmáli', 'Einans innloysing'],
				otherPsp: ['Langtíðarleiga', 'Vibrant', 'Tænastusáttmáli', 'Einans innloysing'],
				numberOfTerminals: ['Stutttíðarleiga'],
			};

			if (!map[field]) {
				return false;
			}

			return map[field].includes(this.form.agreementType?.name);
		},

		customerSearchChange(customer) {
			this.form.customer = customer;
		},
	},
};
</script>

<style lang="scss" scoped>
.selected-customer {
	position: relative;

	display: flex;
	justify-content: space-between;

	padding: 20px;

	background: white;

	border: 1px solid #d8d8d8;
	border-radius: 5px;

	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

	.customer-remove {
		position: absolute;
		top: 0;
		right: 0;
		width: 24px;
		height: 24px;

		display: grid;
		place-items: center;

		color: white;
		background-color: var(--color-red);

		font-size: 16px;
		line-height: 1;

		border-radius: 50%;

		transform: translate(33%, -33%);

		cursor: pointer;

		transition: 0.2s background-color ease;

		&:hover {
			background-color: var(--color-red-600);
		}
	}
}

.date-input {
	position: relative;

	.date-input-icon {
		position: absolute;
		right: 24px;
		top: 50%;
		transform: translateY(-50%);

		font-size: 18px;

		color: var(--color-grey);

		cursor: pointer;
	}
}
</style>
